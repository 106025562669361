<template>
	<el-dialog 
		top="1vh" 
		width="1050px" 
		:title="upl_title" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page"
	>
		<el-form class="form_ordinary" label-width="100px">

			<div class="big_tit" style="margin-top:0px;display:flex;justify-content: space-between;">
				<div>行驶证信息</div>
				<div>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="show_dl">显示行驶证</el-button>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="ocr_dl">识别行驶证</el-button>
				</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车牌号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.truck_plate_num" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车架号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.truck_vin_code" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="能源类型" style="width:388px">
					<el-select class="el_inner_width" v-model="form.en_type">
						<el-option label="汽油" value="A"></el-option>
						<el-option label="柴油" value="B"></el-option>
						<el-option label="电" value="C"></el-option>
						<el-option label="混合油" value="D"></el-option>
						<el-option label="天然气" value="E"></el-option>
						<el-option label="液化石油气" value="F"></el-option>
						<el-option label="甲醇" value="L"></el-option>
						<el-option label="乙醇" value="M"></el-option>
						<el-option label="太阳能" value="N"></el-option>
						<el-option label="混合动力" value="O"></el-option>
						<el-option label="无" value="Y"></el-option>
						<el-option label="其他" value="Z"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货车类型" style="width:588px">
					<el-cascader class="el_inner_width" v-model="form.logink_truck_type" :options="logink_truck_type" placeholder="必填" filterable></el-cascader>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车辆所有人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.truck_owner_name" placeholder="必填" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车辆用途" style="width:588px">
					<el-input class="el_inner_width" v-model="form.truck_used" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车辆总重(吨)" style="width:388px">
					<el-input class="el_inner_width" v-model="form.truck_weight_max" placeholder="必填" type="number" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="发证机关" style="width:588px">
					<el-input class="el_inner_width" v-model="form.dl_ca" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="注册日期" style="width:388px">
					<el-date-picker class="el_inner_width" v-model="form.dl_reg_date" type="date" placeholder="必填"></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="发证日期" style="width:588px">
					<el-date-picker class="el_inner_width" v-model="form.dl_award_date" type="date" placeholder="必填"></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit" style="display:flex;justify-content: space-between;">
				<div>道路运输证信息</div>
				<div>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="show_tl">显示道路运输证</el-button>
					<el-button type="primary" size="mini" style="margin-left:20px" @click="ocr_tl">识别道路运输证</el-button>
				</div>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="道路运输证号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.tl_code" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="经营许可证号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.tl_cert_code" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="save()">仅保存</el-button>
			<el-button type="primary" @click="save_and_upl()">{{upl_btn}}</el-button>
		</div>

		<el-dialog
			top="1vh"
			width="90%"
			:title="img.title"
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="img.is_show"
		>
			<img :src="img.src" style="width:100%">
		</el-dialog>
	</el-dialog>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			aim:String,//上报目标
			aim_text:String,//上报目标(注释,用于显示)
			truck_plate_num:String,//车牌号
			logink_truck_type:Array,//上报用的货车类型
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//车辆编号
				truck_inner_num:'',

				//上报目标
				upl_title:'',

				//上报按钮
				upl_btn:'',

				//表单
				form:{
					truck_plate_num:'',//车牌号
					truck_vin_code:'',//车架号
					en_type:'',//能源类型
					logink_truck_type:[],//货车类型
					truck_owner_name:'',//车辆所有人
					truck_used:'',//车辆用途
					truck_weight_max:'',//车辆总重(吨)
					dl_ca:'',//行驶证发证机关
					dl_reg_date:'',//行驶证注册日期
					dl_award_date:'',//行驶证发证日期
					tl_code:'',//道路运输证号
					tl_cert_code:'',//道路运输证经营许可证号
				},

				img:{
					is_show:false,
					title:'',
					src:''
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.upl_title=`上报到${this.aim_text}`
					this.upl_btn=`保存并上报到${this.aim_text}`
					this.is_show_in_page=true;
					this.init();
					this.img.is_show=false
				}
			},
		},
		methods: {

			//初始化
			init(){

				//如果没有车牌号则不读取数据
				if(!this.truck_plate_num){
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						truck_plate_num:this.truck_plate_num,
					},
					callback:(data)=>{

						//取出数据
						let truck_info=data.list[0]
						this.truck_info=truck_info

						let dl_ocr_rel=JSON.parse(truck_info.dl_ocr_rel)

						this.truck_inner_num=truck_info.truck_inner_num

						//车牌号
						this.form.truck_plate_num=truck_info.truck_plate_num

						//车架号
						this.form.truck_vin_code=truck_info.truck_vin_code?truck_info.truck_vin_code:dl_ocr_rel.truck_vin_code

						//能源类型
						this.form.en_type=truck_info.en_type

						//货车类型
						this.form.logink_truck_type=truck_info.logink_truck_type?truck_info.logink_truck_type.split(','):this.get_logink_truck_type(dl_ocr_rel.vehicle_type)

						//车辆所有人
						this.form.truck_owner_name=truck_info.truck_owner_name?truck_info.truck_owner_name:dl_ocr_rel.truck_owner_name

						//车辆用途
						this.form.truck_used=truck_info.truck_used

						//车辆总重
						this.form.truck_weight_max=truck_info.truck_weight_max

						//发证机关
						this.form.dl_ca=truck_info.dl_ca

						//注册日期
						this.form.dl_reg_date=truck_info.dl_reg_date>0?new Date(truck_info.dl_reg_date*1000):this.get_dl_reg_date(dl_ocr_rel.dl_reg_date)

						//发证日期
						this.form.dl_award_date=truck_info.dl_award_date>0?new Date(truck_info.dl_award_date*1000):this.get_dl_award_date(dl_ocr_rel.dl_award_date)

						//道路运输证信息
						this.form.tl_code=truck_info.tl_code 
						this.form.tl_cert_code=truck_info.tl_cert_code
					}
				})
			},

			//获取发证日期
			get_dl_award_date(dl_award_date){

				if(!dl_award_date){
					return
				}

				dl_award_date=dl_award_date.replace(/-/g,'/'); //必须把日期'-'转为'/'
				return new Date(dl_award_date)
			},

			//获取注册日期
			get_dl_reg_date(dl_reg_date){
				
				if(!dl_reg_date){
					return
				}

				dl_reg_date=dl_reg_date.replace(/-/g,'/'); //必须把日期'-'转为'/'
				return new Date(dl_reg_date)
			},

			//获取上报用货车类型
			get_logink_truck_type(vehicle_type){
				
				if(!vehicle_type){
					return
				}

				for(let item of this.logink_truck_type){

					for(let child of item.children){

						if(child.label==vehicle_type){

							return [item.value,child.value]
						}
					}
				}
			},

			//保存并上报
			save_and_upl(){

				//获取表单数据
				let form=this.make_form();
				if(Object.keys(form).length==0){//没有修改
					
					//直接调起上报函数
					this.$my.other.confirm({
						content:"点击'确定'上报此货车",
						confirm:()=>{
							
							//调接口
							this.$my.net.req({
								data:{
									mod:'truck',
									ctr:'truck_upl_by_admin',
									aim:this.aim,
									truck_plate_num:this.truck_plate_num,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:'操作成功'
									});
									this.is_show_in_page=false
									this.$emit('close');
								},
							});
						}
					});
				}else{//有修改

					//保存数据
					this.$my.net.req({
						data:{
							mod:'truck',
							ctr:'truck_edit_by_admin',
							truck_inner_num:this.truck_inner_num,
							...form
						},
						callback:(data)=>{
							
							//直接调起上报函数
							this.$my.other.confirm({
								content:"点击'确定'上报此货车",
								confirm:()=>{
									
									//调接口
									this.$my.net.req({
										data:{
											mod:'truck',
											ctr:'truck_upl_by_admin',
											aim:this.aim,
											truck_plate_num:this.truck_plate_num,
										},
										callback:(data)=>{
											this.$my.other.msg({
												type:'success',
												str:'操作成功'
											});
											this.is_show_in_page=false
											this.$emit('close');
										},
									});
								}
							});
						},
					});
				}
			},
			save(){//保存

				//获取表单数据
				let form=this.make_form();

				if(Object.keys(form).length==0){
					this.$my.other.msg({
						type:'warning',
						str:'没有修改'
					});
					return;
				}
				
				//询问
				this.$my.other.confirm({
					content:"点击'确定'保存数据",
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_edit_by_admin',
								truck_inner_num:this.truck_inner_num,
								...form
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.is_show_in_page=false
								this.$emit('close');
							},
						});
					}
				});
			},
			make_form(){//构造被修改过的数据

				let form={};
				let old_data=this.truck_info;
				let new_data=this.form;

				//车架号
				if(old_data.truck_vin_code!=new_data.truck_vin_code){

					//格式校验
					if(!this.$my.check.is_vin_code(new_data.truck_vin_code)){
						this.$my.other.msg({
							type:'warning',
							str:'车架号格式有误'
						});
						return;
					}else form.truck_vin_code=new_data.truck_vin_code
				}

				//能源类型
				if(old_data.en_type!=new_data.en_type)form.en_type=new_data.en_type

				//货车类型
				let old_logink_truck_type=old_data.logink_truck_type
				let new_logink_truck_type=new_data.logink_truck_type.join(',')
				if(old_logink_truck_type!=new_logink_truck_type)form.logink_truck_type=new_logink_truck_type

				//车辆所有人
				if(old_data.truck_owner_name!=new_data.truck_owner_name)form.truck_owner_name=new_data.truck_owner_name

				//车辆用途
				if(old_data.truck_used!=new_data.truck_used)form.truck_used=new_data.truck_used

				//车辆总重(吨)
				if(old_data.truck_weight_max!=new_data.truck_weight_max)form.truck_weight_max=new_data.truck_weight_max

				//行驶证发证机关
				if(old_data.dl_ca!=new_data.dl_ca)form.dl_ca=new_data.dl_ca

				//行驶证注册日期
				if(new_data.dl_reg_date){
					let new_dl_reg_date=(new_data.dl_reg_date.getTime()/1000).toFixed(0)
					if(old_data.dl_reg_date!=new_dl_reg_date)form.dl_reg_date=new_dl_reg_date
				}

				//行驶证发证日期
				if(new_data.dl_award_date){
					let new_dl_award_date=(new_data.dl_award_date.getTime()/1000).toFixed(0)
					if(old_data.dl_award_date!=new_dl_award_date)form.dl_award_date=new_dl_award_date
				}
				
				//道路运输证号
				if(old_data.tl_code!=new_data.tl_code)form.tl_code=new_data.tl_code

				//道路运输证经营许可证号
				if(old_data.tl_cert_code!=new_data.tl_cert_code)form.tl_cert_code=new_data.tl_cert_code

				//返回数据
				return form
			},

			//显示行驶证
			show_dl(){
				this.img.src=this.$my.qiniu.make_src('truckdl',this.truck_info.dl_img),
				this.img.title="行驶证"
				this.img.is_show=true;
			},

			//识别行驶证
			ocr_dl(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:'driving_license',
						img_url:this.$my.qiniu.make_src('truckdl',this.truck_info.dl_img)
					},
					callback:(res)=>{
						this.form.dl_ocr_rel=res
						this.form.truck_vin_code=res.truck_vin_code
						this.form.logink_truck_type=this.get_logink_truck_type(res.vehicle_type)
						this.form.truck_owner_name=res.truck_owner_name
						this.form.dl_reg_date=this.get_dl_reg_date(res.dl_reg_date)
						this.form.dl_award_date=this.get_dl_award_date(res.dl_award_date)
					}
				});
			},

			//显示道路运输证
			show_tl(){
				this.img.src=this.$my.qiniu.make_src('trucktl',this.truck_info.tl_img),
				this.img.title="道路运输证"
				this.img.is_show=true;
			},

			//识别道路运输证
			ocr_tl(){

			}
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	
	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.form_ordinary{
		// height: calc(100% - 91px);
		padding: 10px 0;
		overflow-y:auto;
	}

	.el-dialog-s{
		z-index: 11;
	}

</style>