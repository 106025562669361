 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="添加人手机">
				<el-input class="el_input" v-model="form.add_user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加人姓名">
				<el-input class="el_input" v-model="form.add_user_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车辆所有者">
				<el-input class="el_input" v-model="form.truck_owner_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="牌照类型">
				<el-select class="el_input" v-model="form.truck_plate_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="黄牌" value="1"></el-option>
					<el-option label="蓝牌" value="2"></el-option>
					<el-option label="绿牌" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核驳回" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="创建时间">
				<div class="block">
				<el-date-picker
					v-model="build_date"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item"></el-form-item>
			<el-form-item class="el_form_item"></el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="更新时间">
				<div class="block">
				<el-date-picker
					v-model="update_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item"></el-form-item>
			<el-form-item label-width="0" id="button">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="ser_truck_yesterday">昨晚跳过的车辆</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
				<el-button type="success" @click="truck_export_open">导出</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{ list.total }}条</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="truck_list_choose">
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="创建时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="更新时间" prop="update_time_text"></el-table-column>
				<el-table-column label="添加人/所有人">
					<template slot-scope="scope">
						<div>添加: {{scope.row.app_user_info.name}} / {{scope.row.app_user_info.tel}}</div>
						<div>所有人: {{scope.row.truck_owner_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车辆信息">
					<template slot-scope="scope">
						<div>{{scope.row.truck_plate_num?scope.row.truck_plate_num:'未知'}} / {{scope.row.truck_plate_type_text}}</div>
						<div>{{scope.row.truck_type1}}</div>
					</template>
				</el-table-column>
				<el-table-column label="额载信息">
					<template slot-scope="scope">
						<div>额定载重:{{scope.row.truck_weight}}吨</div>
						<div>额载体积{{scope.row.truck_volume}}方</div>
					</template>
				</el-table-column>
				<el-table-column label="道路运输证信息">
					<template slot-scope="scope">
						<div>道路运输证号:{{scope.row.tl_code}}</div>
						<div>经营许可号:{{scope.row.tl_cert_code ?scope.row.tl_cert_code:'0000'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="审核状态">
					<template slot-scope="scope">
						{{scope.row.status_text}}
						<div :class="scope.row.manual_ver==1?'red':'green' ">
							{{scope.row.manual_ver==1?'缺少信息':"证件齐全"}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="驳回原因">
					<template slot-scope="scope">
						{{scope.row.check_faild_reason}}
					</template>
				</el-table-column>
				<el-table-column label="是否默认车辆">
					<template slot-scope="scope">
						{{scope.row.is_default==1?'默认':"非默认"}}
					</template>
				</el-table-column>
				<!-- 操作行 -->
				<el-table-column label="操作" width="48">
					<template slot-scope="scope" v-if="show_btn">
						<el-button v-if="scope.row.status==1" class="btn_left" @click="open_examine_view(scope.row)" size="mini" type="text">审核</el-button>
						<el-button v-if="scope.row.status==2 || scope.row.status==3" class="btn_left" @click="open_check_view(scope.row)" size="mini" type="text">{{scope.row.status==2?'编辑':'详情'}}</el-button>
						<el-button v-if="scope.row.status==2" class="btn_left" @click="open_upl_view(scope.row)" size="mini" type="text">上报</el-button>
						<el-button v-if="scope.row.status==3" class="btn_left" @click="open_examine_view(scope.row)" size="mini" type="text">审核</el-button>
						<!-- <el-button class="btn_left" @click="system_synch(scope.row)" size="mini" type="text">系统同步</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 货车详情 -->
		<truckInfo
			:is_show="truck_info.is_show" 
			:truck_info="truck_info.data" 
			@close="check_view_close"
			@pass="get_page_data"
		></truckInfo>

		<!-- 上报列表 -->
		<truckUplList
			:is_show="truck_upl_list.is_show" 
			:truck_plate_num="truck_upl_list.truck_plate_num" 
		></truckUplList>


		<!-- 添加货车 -->
		<truckAdd
			:is_show="truck_add.is_show"
			@close="get_page_data"
		></truckAdd>
	</div>
</template>

<script>
	import truckInfo from './info.vue'
	import truckAdd from './truck_add.vue'
	import truckUplList from './upl/upl_list.vue'
	import { Loading } from "element-ui";
	import { mapState} from 'vuex'
	export default {
		components:{
			truckInfo,
			truckUplList,
			truckAdd
		},
		computed:{
			...mapState(['user_info'])
		},
		data() {
			return {

				//bucket
				bucket:{},
				bucket_loaded:false,
				
				//搜索条件
				form: {				
					add_user_tel:'',//添加人电话号码
					shipper_tel:'',//货主手机号码
					add_user_name:'',//添加人姓名
					truck_owner_name:'',//收款人姓名(行驶证上的车辆所有者)
					status:'',//状态(1:审核中,2:审核通过,3:审核未通过)
					truck_plate_type:'',//牌照类型
					truck_plate_num:'',//车牌号
					creat_time_start:'', //开始时间
					creat_time_end:'', //结束时间
					update_time_start:'', //开始时间
					update_time_end:'', //结束时间
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				build_date:'',//创建时间
				update_time:'',//更新时间
			
				//时间快捷操作
			    pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//审核弹出层
				truck_info:{
					data:{},
					is_show:0,
				},

				truck_add:{
					is_show:0
				},
				//上报列表
				truck_upl_list:{
					truck_plate_num:'',
					is_show:0,
				},
				show_btn:true,

				//勾选数据
				truc_choosed:'',
			}
		},
		created() {

			//取出参数
			let query=this.$route.query;
			this.form.add_user_tel = query.tel
			this.form.truck_plate_num = query.truck_plate_num
			//权限判断--销售人员隐藏操作按钮
			this.purview_handle();
			
			//获取页面数据
			this.get_page_data()
			
		},
		methods: {
			//车辆系统同步
			system_synch(item){
				this.$my.net.req({
					data:{
					    mod:'res',
						ctr:'synch_truck',
						truck_plate_num:item.truck_plate_num
					},callback:(data)=>{
						console.log(data)
					}
				})
			},
			//数据导出
			truck_export_open(){
				if(this.truc_choosed.length == 0){
					//未勾选数据
					this.$my.other.confirm({
						content:"当前未勾选数据,是否全部导出",
						confirm:()=>{
						//读取数据
						this.$my.net.req({
							data:{
								mod:'truck',
								ctr:'truck_list_by_admin',
								is_get_app_user_info:1,
								...this.form,
								p:1,
								num:1000,
							},
							callback:(data)=>{
								//预处理
									for(let item of data.list){
										//是否可用
										switch(item.status){
											case '1':item.status_text='审核中';break;
											case '2':item.status_text='审核通过';break;
											case '3':item.status_text='审核未通过';break;
										}
										item.name = item.app_user_info.name
										item.tel = item.app_user_info.tel
										item.truck_type =item.truck_type_name +'/'+item.truck_type_name2
										if(item.dl_img){
											let key_arr=item.dl_img.split('__v__')
											item.dl_img1 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.dl_img1 = ''
										}
										if(item.tl_img){
											let key_arr=item.tl_img.split('__v__')
											item.tl_img1 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.tl_img1 = ''
										}
										//驾驶证
										if(item.truck_driver_info.dl_img){
											let key_arr=item.truck_driver_info.dl_img.split('__v__')
											item.driver_dl_img1 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.driver_dl_img1 = ''
										}
										//从业资格证
										if(item.truck_driver_info.cy_img){
											let key_arr=item.truck_driver_info.cy_img.split('__v__')
											item.cy_img1 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.cy_img1 = ''
										}

										//身份证正反面
										if(item.app_user_info.id_card_img1){
											let key_arr=item.app_user_info.id_card_img1.split('__v__')
											item.id_card_img1 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.id_card_img1 = ''
										}
										//从业资格证
										if(item.app_user_info.id_card_img2){
											let key_arr=item.app_user_info.id_card_img2.split('__v__')
											item.id_card_img2 = "http://img.jungong56.com/"+key_arr[0]
										}else{
											item.id_card_img2 = ''
										}

									}
									this.make_csv(data.list)
									Loading.service(this.options);
								}
							})

						}
					});
				}else{
					this.make_csv(this.truc_choosed)
				}
			},
			//生成csv
			make_csv(data){
				var shunxu=[
					{key:"truck_plate_num",cname:"车牌号"},
					{key:"name",cname:"添加人姓名"},
					{key:"tel",cname:"添加人手机号"},
					{key:"truck_weight",cname:"额定载重"},
					{key:"truck_volume",cname:"额载体积"},
					{key:"id_card_img1",cname:"身份证正面"},
					{key:"id_card_img2",cname:"身份证反面"},
					{key:"driver_dl_img1",cname:"行驶证"},
					{key:"cy_img1",cname:"道路运输证"},
					{key:"dl_img1",cname:"行驶证"},
					{key:"tl_img1",cname:"道路运输证"},
					{key:"status_text",cname:"审核状态"},

				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "车辆列表.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//勾选
			truck_list_choose(event){
				this.truc_choosed=event
			},
			//权限判断
			purview_handle(){
				let ug_ids=[0,6,7];
				if(ug_ids.indexOf(this.user_info.ug_id)!=-1){
					this.show_btn=false;
				}
			},
			//审核通过
			open_examine_view(item){
				this.open_check_view(item)
				//询问
				// this.$my.other.confirm({
				// 	content:"点击'确定'通过审核",
				// 	confirm:()=>{
				// 		//调接口
				// 		this.$my.net.req({
				// 			data:{
				// 				mod:'truck',
				// 				ctr:'truck_pass_by_admin',
				// 				truck_inner_num:item.truck_inner_num,
				// 			},
				// 			callback:(data)=>{
				// 				this.$my.other.msg({
				// 					type:'success',
				// 					str:'操作成功'
				// 				});
				// 				this.get_page_data()
				// 			},
				// 		});
				// 	}
				// });
			},
			//添加货车
			open_add_view(){
				this.truck_add.is_show ++
			},
			//上报界面
			open_upl_view(item){

				//置入数据
				this.truck_upl_list.truck_plate_num=item.truck_plate_num

				//打开弹出层
				this.truck_upl_list.is_show++;
			},
			truck_details(item){
				//置入数据
				this.truck_info.data=item

				//打开弹出层
				this.truck_info.is_show++;

				// 加载完毕
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//打开货车详情
			open_check_view(item){
				console.log(item)
				//ocr自动识别
				Loading.service(this.options);
				if(item.status == 1 || item.status == 3 || !item.truck_vin_code || !item.truck_type1){
					// if(!item.truck_vin_code){
						// 状态待审核 识别号未空 开启 ocr
						var truck_igm=this.$my.qiniu.make_src('truckdl',item.dl_img)
						this.$my.net.req({
							take_over_control:1,
							data:{
								mod:'res',
								ctr:'img_ocr',
								img_type:'driving_license',
								img_url:truck_igm
							},
							callback:(data)=>{
							
							if(data.code ==0){
								//为空置入数据
								if(!item.truck_owner_name){
									item.truck_owner_name = data.msg.truck_owner_name
								}
								item.truck_type1 = data.msg.truck_type
								if(!item.truck_vin_code){
									item.truck_vin_code = data.msg.truck_vin_code
								}
								if(!item.dl_reg_date && !data.msg.dl_reg_date){
									item.dl_reg_date = ""
								}else if(item.dl_reg_date==0){
									item.dl_reg_date = new Date(data.msg.dl_reg_date).getTime() /1000;
								}
								if(!item.dl_award_date && !data.msg.dl_award_date){
									item.dl_award_date = ""
								}else if(item.dl_award_date==0){
									item.dl_award_date = new Date(data.msg.dl_award_date).getTime() / 1000;
								}
								 if(!item.truck_plate_num){
									item.truck_plate_num = data.msg.truck_plate_num
								}
								 if(!item.dl_ca){
									item.dl_ca = data.msg.truck_seal
								}
								this.truck_details(item)
							}else{
								//读取失败
								this.truck_details(item)
							}
						}
						})
					// }
				}else{
					this.truck_details(item)
				}
			},
			check_view_close(){//上报界面关闭回调

				//复位
				this.truck_info.is_show=0;

				//清空数据
				this.truck_info.data={}
				
				//刷新页面
				this.get_page_data()
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					add_user_tel:'',//添加人电话号码
					shipper_tel:'',//货主手机号码
					truck_owner_name:'',//收款人姓名(行驶证上的车辆所有者)
					status:'2',//状态(1:审核中,2:审核通过,3:审核未通过)
					truck_plate_type:'',//牌照类型
					truck_plate_num:'',//车牌号
					creat_time_start:'', //开始时间
					creat_time_end:'', //结束时间
					update_time_start:'', //开始时间
					update_time_end:'', //结束时间
				}
				this.build_date = "";
				this.update_time = []
				this.page.p =1
				this.get_page_data()
			},
			
			//昨天发车，到货，或者支付的待审核的车辆
			ser_truck_yesterday(){
			
				let status=this.form.status;
				
				//初始化搜索
				this.form={
					add_user_tel:'',//添加人电话号码
					shipper_tel:'',//货主手机号码
					truck_owner_name:'',//收款人姓名(行驶证上的车辆所有者)
					status:status,//状态(1:审核中,2:审核通过,3:审核未通过)
					truck_plate_type:'',//牌照类型
					truck_plate_num:'',//车牌号
					creat_time_start:'', //开始时间
					creat_time_end:'', //结束时间
					update_time_start:'', //开始时间
					update_time_end:'', //结束时间
				}
				this.build_date = "";
				this.update_time = []
				this.page.p =1
				
				// this.form.status=1;
				this.form.ser_yesterday=2; //1不限  2搜索昨晚发到货支付的待审核司机
			
				this.get_page_data();
				
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				if (this.build_date) {
					this.form.creat_time_start = parseInt(this.build_date[0] / 1000);
					this.form.creat_time_end = parseInt(this.build_date[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";

				}
				if (this.update_time) {
					this.form.update_time_start = parseInt(this.update_time[0] / 1000);
					this.form.update_time_end = parseInt(this.update_time[1] / 1000);
				} else {
					this.form.update_time_start = "";
					this.form.update_time_end = "";
				}
				this.page.p = 1;
				this.get_page_data();
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){
				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck',
						ctr:'truck_list_by_admin',
						is_get_app_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						//加载完毕
						this.list.loading=false
						if(data.code !=0){
							this.$my.other.msg({
								type:'info',
								str:data.error_info,
							});
							this.list.data=[]
							return
						}
					

						//总数
						this.list.total=data.msg.max

						//预处理
						for(let item of data.msg.list){
							//创建时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//更新时间
							item.update_time_text=this.$my.other.totime(item.update_time);
							//是否可用
							switch(item.status){
								case '1':item.status_text='审核中';break;
								case '2':item.status_text='审核通过';break;
								case '3':item.status_text='审核未通过';break;
							}

							//车牌照类型
							switch(item.truck_plate_type){
								case '0':item.truck_plate_type_text='未知';break;
								case '1':item.truck_plate_type_text='黄牌';break;
								case '2':item.truck_plate_type_text='蓝牌';break;
								case '3':item.truck_plate_type_text='绿牌';break;
							}

							//能源类型
							switch(item.en_type){
								case 'A':item.en_type_text='汽油';break;
								case 'B':item.en_type_text='柴油';break;
								case 'C':item.en_type_text='电';break;
								case 'D':item.en_type_text='混合油';break;
								case 'E':item.en_type_text='天然气';break;
								case 'F':item.en_type_text='液化石油气';break;
								case 'L':item.en_type_text='甲醇';break;
								case 'M':item.en_type_text='乙醇';break;
								case 'N':item.en_type_text='太阳能';break;
								case 'O':item.en_type_text='混合动力';break;
								case 'Y':item.en_type_text='无';break;
								case 'Z':item.en_type_text='其他';break;
							}

							//货车类型
							if(!item.truck_type_name){
								item.truck_type_name="无"
							}
							
							//货车类型
							if(!item.truck_type_name2){
								item.truck_type_name2="无"
							}
							item.name = item.app_user_info.name
							item.tel = item.app_user_info.tel
							item.truck_type1 = item.truck_type
							item.truck_type =item.truck_type_name +'/'+item.truck_type_name2
							
							if(item.dl_img){
								let key_arr=item.dl_img.split('__v__')
								item.dl_img1 = "http://img.jungong56.com/"+key_arr[0]
							}else{
								item.dl_img1 = ''
							}
							if(item.tl_img){
								let key_arr=item.tl_img.split('__v__')
								item.tl_img1 = "http://img.jungong56.com/"+key_arr[0]
							}else{
								item.tl_img1 = ''
							}
							//无车代码
							let logink_truck_code=item.logink_truck_type.split(',')[1];
							item.logink_truck_code=logink_truck_code?logink_truck_code:"无"
						}

						//渲染
						this.list.data=data.msg.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//弹出层
	.in_tab_img_item{
		width: 500px;
	}

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total_info {
	text-align: right;
	font-size: 12px;
	margin-bottom: 24px;
	margin-right: 1px;
	margin-top: 10px;
	color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.item {
	font-weight: 800;
	}
	#button{
		margin-left: -120px;
	}
	.green{
		color: rgb(13, 230, 13);
	}
	.red{
		color: red;
	}
</style>